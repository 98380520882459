@font-face {
    font-family: 'GenYoGothic TW TTF';
    src: url('GenYoGothicTW-Heavy-TTF.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GenYoGothic TW TTF';
    src: url('GenYoGothicTW-Bold-TTF.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GenYoGothic TW TTF';
    src: url('GenYoGothicTW-Light-TTF.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GenYoGothic TW TTF';
    src: url('GenYoGothicTW-Medium-TTF.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GenYoGothic TW TTF Normal';
    src: url('GenYoGothicTW-Normal-TTF.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GenWanMinTW';
    src: url('GenWanMinTW-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.da-light {
    font-weight: 300;
}

.da-medium {
    font-weight: 500;
}

.da-heavy {
    font-weight: 900;
}

.da-normal {
    font-weight: normal;
}

.da-bold {
    font-weight: bold;
}