@media (max-width: 576px){
  .sub-header-box {
    font-size: calc(12.48px + 2vw);
    color: #323551;
    font-family: 'GenYoGothic TW TTF';
    font-weight: 300;
    margin: auto;
    white-space: nowrap;
  }
}

@media (min-width: 576px){
  .sub-header-box {
    color: #323551;
    font-family: 'GenYoGothic TW TTF';
    font-weight: 300;
    margin: auto;
    white-space: nowrap;
  }
}

.long-header-box {
  width: 80%;
  max-width: 1200px;
  margin: auto;
}

