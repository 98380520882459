.sub-cover-box-image {
  aspect-ratio: 4;
  background-image: url('img/banner-black.jpg');
  background-size: cover;
  max-height: 45%;
  background-position: center;
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .sub-cover-box-image {
      padding-top: 15%;
    }
}}

.sub-cover-padbox {
  width: 100%;
  height: calc(60% - 0.5vw);
}

@media (max-width:768px) {
  
  .sub-cover-box-text1 {
    margin-top: -2vw;
    margin-left: 0;
    padding-left: 3%;
    color: #fff;
    font-size: calc(10px + 1.5vw);
    text-align: center;
  }
  
  .sub-cover-box-text2 {
    margin-left: 0;
    padding-left: 3%;
    color: #fff;
    font-size: calc(32px + 2vw);
    text-align: center;
  }  
}

@media (min-width:768px) {
  
  .sub-cover-box-text1 {
    margin-left: 0;
    padding-left: calc(3% + 12px);
    color: #fff;
    font-size: calc(13px + 0.5vw);
  }
  
  .sub-cover-box-text2 {
    margin-top: -0.5vw;
    padding-left: calc(3% + 12px);
    color: #fff;
    font-size: calc(22px + 2vw);
  }
}

.event-box {
  width: 70%;
  max-width: 1050px;
  margin: auto;
  margin-top: 35px;
}

.event-header {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  margin: auto;
  margin-bottom: 7px;
  color: #fff;
  background-color: #f0c934;
  text-align: center;
  font-size: 20px;
}

@media (max-width: 576px){
  .event-item { 
    padding-top: 10px;
    padding-bottom: 7px;
    font-size: calc(8.6px + 2vw);
  }
}

@media (min-width: 576px){
  .event-item { 
    padding-top: 10px;
    padding-bottom: 7px;
    font-size: 20px;
  }
}

.event-item-box {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}

.event-item-name-pad {
  height: calc(50% - 15px);
}

.artist-list {
  width: 70%;
  margin: auto;
}

.artist-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.artist-pic-box {
  width: 80%;
  margin: auto;
}

.artist-text-box {
  width: 80%;
  margin: auto;
  white-space: nowrap;
}

.artist-text-name {
  color: #303450;
  font-weight: bold;
  font-size: 20px;
}

.artist-text-circle {
  color: #303450;
  font-weight: bold;
  font-size: 14px;
}

.artist-text-link {
  color: #8DA8C0;
  padding-left: 0;
}

.outline-item {
  padding-top: 1vw;
  padding-bottom: 0.5vw;
}

.outline-box {
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
}

.outline-toppad {
  height: 30%;
}

.outline-text-box {
  background: linear-gradient(90deg, transparent 2%, rgba(240, 201, 52, 1) 2%, rgba(240, 201, 52, 1) calc(2% + 6px), transparent calc(2% + 6px));
  height: 65%;
}

@media (max-width: 1000px){
  .outline-text-padbox {
    height: calc(65% - 6.25vw - 10px);
  }

  .outline-text {
    font-size: calc(5px + 1.75vw);
    line-height: calc(5px + 2.25vw);
    vertical-align: middle;
    white-space: nowrap;
    padding: 0;
  }
}

@media (min-width: 1000px){
  .outline-text-padbox {
    height: calc(70% - 82.5px);
  }

  .outline-text {
    font-size: 22.5px;
    line-height: 27.5px;
    vertical-align: middle;   
    padding: 0;
  }
}



.fading-text {
  font-size: 20px;
}

.fading-text-wrapper {
  width: 70%;
  max-width: 1050px;
  margin: auto;
  padding-left: 0px;
  padding-top: 14px;
  padding-bottom: 11px;
}