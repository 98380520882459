@import url("./fonts/font.css");

body {
  font-family: 'GenYoGothic TW TTF';
  font-weight: 300;
  color: #323551;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'GenYoGothic TW TTF Normal';
  /* src: url('#{resource["fonts/GenYoGothicTW-Normal-TTF.woff2"]}') format('woff2'),
      url('#{resource["fonts/GenYoGothicTW-Normal-TTF.woff"]}') format('woff'),
      url('#{resource["fonts/GenYoGothicTW-Normal-TTF.ttf"]}') format('truetype'); */
    src: url('./fonts/GenYoGothicTW-Normal-TTF.woff2') format('woff2'),
    url('./fonts/GenYoGothicTW-Normal-TTF.woff') format('woff'),
    url('./fonts/GenYoGothicTW-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FZDBSB';
  src: url('./fonts/FZDBSB.woff2') format('woff2'),
  url('./fonts/FZDBSB.woff') format('woff'),
  url('./fonts/FZDBSB.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.color-nav {
  background-color: #f0c934;
}

.topbar-box {
  max-width: 1200px;
}
@media (max-width:576px) {
  .topbar {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .topbar-main {
    font-size: 16px;
    text-align: left;
    color: white;
    font-family: 'GenYoGothic TW TTF';
    font-weight: 500;
  }
  .topbar-sub {
    font-size: 16px;
    text-align: right;
    color: white;
    font-weight: 500;
  }
}
@media (min-width:576px) {
  .topbar-main {
    font-size: 16px;
    text-align: center;
    color: white;
    font-family: 'GenYoGothic TW TTF';
  }
  .topbar-sub {
    font-size: 12px;
    text-align: center;
    color: white;
    margin-top: -8px;
  }
}

.nav-link {
  padding: 0px;
}

.pad-box {
  padding-top:50px;
}

.bottom-pad-box {
  padding-top:100px;
}

/* .body-box {
  max-width: 1200px;
  margin: auto;
} */

.cover-box {
  max-width: 1200px;
  margin: auto;
}

.cover-box-image {
  height: calc((100vw - 24px - 80px) / 1.6271);
  background-image: url('img/banner.jpg');
  background-size: cover;
  max-height: 674.45px;
  background-position: center;
}

@media (max-width: 768px) {
  .cover-box-margin {
    width: calc(100% - 0px);
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .cover-box-margin {
    width: calc(100% - 80px);
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
  }
}

.cover-inner-box {
  padding-top: 7%;
  padding-bottom: 7%;
  max-width: 80%;
  margin: auto;
}

.cover-inner-box-content {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .4);
  opacity: 1;
}

.cover-inner-margin {
  margin: 0px;
  padding: 2px;
}

.cover-inner-margin-box {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  border-width: 2px;
  border-color: rgba(255, 255, 255, .6);
  border-style: solid;
}

.cover-title {
  text-align: center;
  font-size: 40px;
}

.cover-sub {
  text-align: center;
  font-size: 24px;
  background: linear-gradient(180deg, transparent 38%, rgba(240, 201, 52, 1) 38%, rgba(240, 201, 52, 1) 70%, transparent 70%);
}

.cover-sub2 {
  text-align: center;
  font-size: 24px;
}

.intro-box {
  background: linear-gradient(180deg, transparent 35%, rgba(240,201,52,1) 35%);
}

.intro-inner-box {
  max-width: 1200px;
}

@media not all and (min-resolution:.001dpcm){
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    
    @media (max-width: 576px) {
      .intro-image-box {
        height: calc((100vw - 78px) * 5 / 12 * 0.85 * 4 / 3);
      }
    }

    @media (min-width:576px) {

      .intro-image-box {
        height: calc((100vw - 78px) * 4 / 12 * 0.85 * 4 / 3);
        aspect-ratio: 3/4;
      }
    }

    @media (min-width:768px) {
      .intro-image-box {
        height: calc((100vw - 148px) * 0.8 * 4 / 12 * 0.95 * 4 / 3);
      }
    }

    @media (min-width: 992px) {
      .intro-image-box {
        height: calc((100vw - 148px) * 0.8 * 4 / 12 * 0.95 * 4 / 3);
      }
    }
  }
}

@media not all and (min-resolution:.001dpcm){
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    
    @media (max-width: 768px) {
      .outline-image-box {
        height: calc((100vw - 78px) * 5 / 12 * 0.85 * 4 / 3);
      }
    }

    @media (min-width:768px) {
      .outline-image-box {
        height: calc((100vw - 148px) * 0.8 * 4 / 12 * 0.95 * 4 / 3);
      }
    }

    @media (min-width: 992px) {
      .outline-image-box {
        height: calc((100vw - 148px) * 0.8 * 4 / 12 * 0.95 * 4 / 3);
      }
    }
  }
}


@media (max-width:768px) {
  .intro-content-box {
    max-width: 100%;
  }
}

@media (min-width:768px) {
  .intro-content-box {
    max-width: 80%;
  }
}

.intro-image-box {
  background-position: center;
  background-size: cover;
  margin-left: 2.5%;
  width: 95%;
  aspect-ratio: 3/4;
  max-height: 363.525px;
  border: 2px solid #fff;
  box-shadow: 5px 5px 5px #ccc;
  -moz-box-shadow: 5px 5px 5px #ccc;
  -webkit-box-shadow: 5px 5px 5px #ccc;
  -khtml-box-shadow: 5px 5px 5px #ccc;
  margin-bottom: 5px;
}

.outline-image-box {
  background-position: center;
  background-size: cover;
  margin-left: 2.5%;
  width: 95%;
  aspect-ratio: 3/4;
  max-height: 363.525px;
  border: 2px solid #fff;
  box-shadow: 5px 5px 5px #ccc;
  -moz-box-shadow: 5px 5px 5px #ccc;
  -webkit-box-shadow: 5px 5px 5px #ccc;
  -khtml-box-shadow: 5px 5px 5px #ccc;
  margin-bottom: 5px;
}

.intro-image-front {
  background-image: url('img/front.jpg');
}

.intro-image-back {
  background-image: url('img/back.jpg');
}

.intro-slide-box {
  width: 30%;
  aspect-ratio: 3/4;
}

@media (max-width: 1000px){
  .intro-toppad {
    top: 35%;
    height: calc(36% - 3vw);
  }
  
  .intro-text-2-pad{
    height: calc(26% - 6.75vw);
  }
  
  .intro-text-3-pad{
    height: calc(30% - 6.75vw);
  }
  
  .intro-text-1 {
    color: #323551;
    font-size: 3vw;
    line-height: 3vw;
    vertical-align: middle;
    padding-left: 1.5vw;
    font-family: 'GenWanMinTW';
    white-space: nowrap;
  }
  
  .intro-text-2 {
    font-size: 1.75vw;
    line-height: 2.625vw;
    vertical-align: middle;
    padding-left: 1.5vw;
    white-space: nowrap;
  }
  
  .intro-text-3 {
    font-size: 2.625vw;
    vertical-align: middle;
    color: #fff;
    padding-left: 1.5vw;
    font-family: 'GenWanMinTW';
    white-space: nowrap;
  }  
}

@media (min-width: 1000px){
  .intro-toppad {
    top: 35%;
    height: calc(36% - 20px - 1vw);
  }
  
  .intro-text-2-pad{
    height: calc(26% - 47.5px - 2vw);
  }
  
  .intro-text-3-pad{
    height: calc(30% - 47.5px - 2vw);
  }
  
  .intro-text-1 {
    color: #323551;
    font-size: calc(20px + 1vw);
    line-height: calc(20px + 1vw);
    vertical-align: middle;
    padding-left: 2.5vw;
    font-family: 'GenWanMinTW';
    white-space: nowrap;
  }
  
  .intro-text-2 {
    font-size: calc(7.5px + 1vw);
    line-height: calc(16.25px + 1vw);
    vertical-align: middle;
    padding-left: 2.5vw;
    white-space: nowrap;
  }
  
  .intro-text-3 {
    font-size: calc(16.25px + 1vw);
    vertical-align: middle;
    color: #fff;
    padding-left: 2.5vw;
    font-family: 'GenWanMinTW';
    white-space: nowrap;
  }  
}


.footer-text {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding-top: 20px;
}

.footer-text-small {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 10px;
  padding-bottom: 20px;
}

.bottom-nav {
  width: 100%;
}

.logo-row {
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo-row-col {
  max-width: 300px;
  align-self: center;
  margin:auto;
}

@media (max-width: 576px) {
  .logo-scale {
    width: 5.208vw;
    height: 5.208vw;
  }

  .logo-scale-mail {
    width: calc(5.208vw * 1.5);
    height: 5.208vw;
  }
}

@media (min-width: 576px) {
  .logo-scale {
    width: 30px;
    height: 30px;
  }

  .logo-scale-mail {
    height: 30px;
    width: 45px;
  }
}

.twitter-logo {
  background-position: center;
  background-size: cover;
  background-image: url('img/twitter.png');
  aspect-ratio: 1;
  padding: 0;
}

.fb-logo {
  background-position: center;
  background-size: cover;
  background-image: url('img/fb.png');
  aspect-ratio: 1;
  padding: 0;
}

.insta-logo {
  background-position: center;
  background-size: cover;
  background-image: url('img/insta.png');
  aspect-ratio: 1;
  padding: 0;
}

.mail-logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('img/mail.png');
  aspect-ratio: 1.5;
  padding: 0;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #f0c934;
}

.update-box {
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
}

.news-title {
  padding-top: 10px;
  font-weight: 900;
}

.news-content {
  padding-bottom: 4px;
}

.da-db {
  color: #323551;
}

.da-wh {
  color: #fff;
}

.da-lb {
  color: #8DA8C0;
}

a.da-link {
  color: #8DA8C0;
}